// A collection of functions for device types, screen size, orientation
// and CSS/HTML helper functions

// Warning: beware of the lexical scoping of arrow functions
// e.g. `this` does not refer to the object

// this needs to be `var` if it's going to be attached to the window directly in the browser
const screenAndStyle = {
  inBrowser() {
    return (typeof (window.navigator) !== 'undefined');
  },
  isBootstrapXS() {
    return this.findBootstrapEnvironment() === 'xs';
  },
  isBootstrapSM() {
    return this.findBootstrapEnvironment() === 'sm';
  },
  isBootstrapMD() {
    return this.findBootstrapEnvironment() === 'md';
  },
  isBootstrapLG() {
    return this.findBootstrapEnvironment() === 'lg';
  },
  isBootstrapLGDown() {
    return ['md', 'sm', 'xs'].includes(this.findBootstrapEnvironment());
  },
  isTouch() {
    if (screenAndStyle.inBrowser()) {
      if (typeof window.isTouchCache === 'undefined') {
        window.isTouchCache = 'ontouchstart' in document;
      }

      return window.isTouchCache;
    }

    return false;
  },
  isInternetExplorer() {
    if (screenAndStyle.inBrowser()) {
      return (window.navigator.userAgent.indexOf('MSIE ') > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
    }

    return false;
  },
  isSafari() {
    if (screenAndStyle.inBrowser()) {
      return (window.navigator.vendor.indexOf('Apple') > -1);
    }

    return false;
  },
  isEdge() {
    if (screenAndStyle.inBrowser()) {
      return (window.navigator.userAgent.indexOf('Edge') > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
    }
    return false;
  },
  isMobileBrowser() {
    // We recommend looking for the string “Mobi” anywhere in the User Agent to detect a mobile device.
    // -- https://web.archive.org/web/20200801022002/https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Tablet_or_Desktop
    if (screenAndStyle.inBrowser()) {
      return (window.navigator.userAgent.indexOf('Mobi') > -1); // may be within a word like "IEMobile"
    }
    return false;
  },
  assumePhoneOrSmallScreen() {
    if (screenAndStyle.inBrowser()) {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const iosNotIpad = /iphone|ipod/.test(userAgent);

      return window.innerWidth <= 500 || iosNotIpad;
    }

    return false;
  },
  findBootstrapEnvironment() {
    if (screenAndStyle.inBrowser()) {
      if (!window.bootstrapEnvironmentCache || !window.bootstrapEnvironmentCache[window.innerWidth]) {
        window.bootstrapEnvironmentCache || (window.bootstrapEnvironmentCache = {});
        window.bootstrapEnvironmentCache[window.innerWidth] = getBootstrapEnvironment();
      }

      return window.bootstrapEnvironmentCache[window.innerWidth];

      function getBootstrapEnvironment() {
        const envs = ['sm', 'md', 'lg', 'xl'];
        let env = '';

        const $el = $('<div>');
        $el.appendTo($('body'));
        $el.addClass('d-block');
        for (let i = envs.length - 1; i >= 0; i--) {
          env = envs[i];
          $el.addClass(`d-${env}-none`);
          if ($el.is(':hidden')) {
            $el.remove();
            return env;
          }
        }
        $el.remove();
        return 'xs'; // extra small
      }
    }

    return 'md';
  },
};

export default screenAndStyle;
